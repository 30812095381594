import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import usePermissions from '../../hooks/permissions/usePermissions';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { dataAttributeProps } from '../../utils/ComponentUtils';
import { clientFormRoute } from '../../utils/NavigationUtils';
import DataRow from '../shared/data-grid/DataRow';
import Tooltip from '../shared/Tooltip';
import DateUtils from '../../utils/DateUtils';
import { Risk, RiskCategory, RiskRatingKeys, RiskStatusKeys } from '../../models/Risk';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import User from '../../models/User';
import LanguageUtils from '../../utils/LanguageUtils';
import StringUtils from '../../utils/StringUtils';
import { EventSystem } from '../../events/EventSystem';
import RiskUtils, { RiskRating } from '../../utils/RiskUtils';
import Badge from '../shared/badge/Badge';
import InfoIcon from '../shared/icon/InfoIcon';
import EditIcon from '../shared/icon/EditIcon';
import { Roles } from '../../models/Role';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';

const RiskRow: FC<{ risk: Risk; riskCategory: RiskCategory; owner?: User }> = (props) => {
  const { t } = useTranslation(['risk', 'common']);
  const { risk, riskCategory, owner } = props;
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  const hasPermission = usePermissions();

  const actionTitle = useMemo(
    () =>
      interpolateActionData(
        LanguageUtils.getTranslation('question', risk.actionTranslations) || LanguageUtils.getTranslation('title', risk.actionTranslations),
        risk.placeholders,
      ),
    [risk.actionTranslations, risk.placeholders],
  );

  const probabilities = useMemo(
    () =>
      riskCategory.matrix.rowIds.map((x) => ({
        id: x,
        text: LanguageUtils.getTranslation(x, riskCategory.matrix.translations),
        value: x,
      })),
    [riskCategory.matrix.rowIds, riskCategory.matrix.translations],
  );

  const impacts = useMemo(
    () =>
      riskCategory.matrix.columnIds.map((x) => ({
        id: x,
        text: LanguageUtils.getTranslation(x, riskCategory.matrix.translations),
        value: x,
      })),
    [riskCategory.matrix.columnIds, riskCategory.matrix.translations],
  );

  const clientFormSubTitle = useMemo(() => {
    return risk.documentNumber !== null
      ? `${StringUtils.makePrefixWithNumber(undefined, risk.documentNumber, risk.templateModuleTranslations)}-${risk.clientFormSubtitle}`
      : risk.clientFormSubtitle;
  }, [risk.clientFormSubtitle, risk.documentNumber, risk.templateModuleTranslations]);

  return (
    <>
      {currentClient && (
        <DataRow
          {...dataAttributeProps(props)}
          url={
            clientFormRoute({ clientId: currentClient?.id, id: risk.clientFormId }, currentUser) +
            `?highlight=${risk.clientFormId},${risk.sectionId},${risk.actionId}#risks`
          }
          contextMenuItems={[
            {
              title: t('risk:list.context-menu.edit'),
              onClick: () => {
                EventSystem.fireEvent('question-risk-edit', { risk: risk });
              },
              hide: !hasPermission([Roles.Employee, Roles.ExternalContributor]) && hasPermission(Roles.ExternalAuditor),
              icon: <EditIcon className="h-5 w-5" />,
            },
            {
              title: t('risk:list.context-menu.info'),
              onClick: () => EventSystem.fireEvent('question-risk-view', { risk: risk }),
              icon: <InfoIcon className="h-5 w-5" />,
            },
          ]}
        >
          <div className="flex flex-grow items-start text-left">
            <div className="w-10">{risk.friendlyId}</div>
            <div className="w-full pr-4">
              <Tooltip text={risk.title} truncatedTextMode>
                {(tooltip) => (
                  <div className="min-w-full max-w-0 truncate font-medium text-black" {...tooltip}>
                    {risk.title}
                  </div>
                )}
              </Tooltip>
              <Tooltip text={risk.description} truncatedTextMode>
                {(tooltip) => (
                  <div className="text-color-2 min-w-full max-w-0 truncate" {...tooltip}>
                    {risk.description || '-'}
                  </div>
                )}
              </Tooltip>
            </div>
          </div>
          <div className="w-40 pr-4 xl:w-60">
            <Tooltip text={clientFormSubTitle} truncatedTextMode>
              {(tooltip) => (
                <div className="text-gray-2 min-w-full max-w-0 truncate font-medium" {...tooltip}>
                  {clientFormSubTitle}
                </div>
              )}
            </Tooltip>
            <Tooltip text={actionTitle} truncatedTextMode>
              {(tooltip) => (
                <div className="text-semantic-5 min-w-full max-w-0 truncate" {...tooltip}>
                  {actionTitle}
                </div>
              )}
            </Tooltip>
          </div>
          <div className="w-40 xl:w-52">
            <span className="block">{t(RiskStatusKeys[risk.status])}</span>
            <span className="block">{DateUtils.formatDateTime(new Date(risk.statusUtc))}</span>
          </div>
          <div className={`w-52 ${RiskUtils.textColorForRiskLevel(riskCategory.matrix.levels[risk.matrixHorizontalId])} font-[450]`}>
            {probabilities.find((x) => x.id === risk.matrixHorizontalId)?.text}
          </div>
          <div className={`w-40 ${RiskUtils.textColorForRiskLevel(riskCategory.matrix.levels[risk.matrixVerticalId])} font-[450]`}>
            {impacts.find((x) => x.id === risk.matrixVerticalId)?.text}
          </div>
          <div className="w-32">
            <Badge
              text={t(RiskRatingKeys[risk.riskRating])}
              textSize="text-dpm-14"
              textClass={risk.riskRating === RiskRating.CRITICAL_RISK ? 'text-white' : RiskUtils.textColorForRating(risk.riskRating)}
              backgroundClass={`${RiskUtils.bgColorForRating(risk.riskRating)}`}
            />
          </div>
          <div className="w-44">{DateUtils.formatDateTime(new Date(risk.lastModifiedUtc))}</div>
          <div className="w-40 pr-6">
            <ProfileAvatar user={owner} size={ImageSize.S} />
          </div>
        </DataRow>
      )}
    </>
  );
};

export default RiskRow;
