import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import { InputStyle } from '../../components/shared/form-control/Input';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import SkeletonLoader from '../../components/shared/skeleton-loader/SkeletonLoader';
import FilterTag, { FilterSelectMode } from '../../components/shared/tags/FilterTag';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Roles } from '../../models/Role';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { useStoredFilters } from '../../hooks/useStoredFilters';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import { Option } from '../../components/Option';
import throttle from 'lodash.throttle';
import useDebounce from '../../hooks/useDebounce';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import ClientService from '../../services/ClientService';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { Risk, RiskStatus, riskRatingOptions, riskStatusOptions } from '../../models/Risk';
import RiskList from '../../components/risk/RiskList';
import { ApiResponse } from '../../models/ApiResponse';
import RiskService from '../../services/RiskService';
import { nextTick } from '../../utils/ReactUtils';
import { EventSystem } from '../../events/EventSystem';
import RiskModal from '../../components/risk/RiskModal';
import QuestionRiskCreatedEvent from '../../events/QuestionRiskCreatedEvent';
import User from '../../models/User';
import { RiskRating } from '../../utils/RiskUtils';
import StringUtils from '../../utils/StringUtils';

const RiskOverview = () => {
  const { t } = useTranslation(['risk', 'common']);
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const { id: currentPageType } = useCurrentRoute();
  const currentPageId = useMemo(() => `${currentClient?.id}-${currentPageType}`, [currentClient?.id, currentPageType]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isRisksLoading, setIsRisksLoading] = useState(true);
  const [riskSearchPhrase, setRiskSearchPhrase] = useLocalStorageState(`${currentPageId}-search`, '');
  const [sortBy, setSortBy] = useState(
    '-friendlyId,+title,+subtitle,+status,+matrixHorizontalLevel,+matrixVerticalLevel,+riskRating,+lastModifiedUtc,+ownerName',
  );
  const debouncedSearchTerm = useDebounce(riskSearchPhrase, 500);
  const hasPermission = usePermissions();
  const [clientUsers, setClientUsers] = useState<User[]>([]);
  const [clientUsersOptions, setClientUsersOptions] = useState<Option<string, boolean>[]>([]);
  const [clientUsersFilterDefaults, setClientUsersFilterDefaults] = useState<Option<string, boolean>[]>([]);
  const [risks, setRisks] = useState<ApiResponse<Risk[]> | null>(null);
  const [clientFormOptions, setClientFormOptions] = useState<Option<string, boolean>[]>([]);
  const [clientFormFilterDefaults, setClientFormFilterDefaults] = useState<Option<string, boolean>[]>([]);

  const defaultStatusFilters = useMemo<ComponentProps<typeof FilterTag>['options']>(() => {
    return [...riskStatusOptions(t).map((x) => ({ ...x, value: false }))];
  }, [t]);

  const defaultRatingFilters = useMemo<ComponentProps<typeof FilterTag>['options']>(() => {
    return [...riskRatingOptions(t).map((x) => ({ ...x, value: false }))];
  }, [t]);

  const filterDefaults = useMemo(
    () => ({
      ratingFilters: defaultRatingFilters,
      statusFilters: defaultStatusFilters,
      clientUsersFilters: clientUsersFilterDefaults,
      clientFormsFilter: clientFormFilterDefaults,
    }),
    [defaultRatingFilters, defaultStatusFilters, clientUsersFilterDefaults, clientFormFilterDefaults],
  );

  const [
    { statusFilters, ratingFilters, clientUsersFilters, clientFormsFilter },
    { setStatusFilters, setRatingFilters, setClientUsersFilters, setClientFormsFilter },
    { hasFiltersApplied, clearFilters },
  ] = useStoredFilters(`${currentPageId}`, filterDefaults);

  const applyUsersFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setClientUsersFilters(filters);
    },
    [setClientUsersFilters],
  );

  const applyClientFormsFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setClientFormsFilter(filters);
    },
    [setClientFormsFilter],
  );

  const applyStatusFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setStatusFilters(filters);
    },
    [setStatusFilters],
  );

  const applyRatingFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setRatingFilters(filters);
    },
    [setRatingFilters],
  );

  const ownersFilterSearch = useMemo(() => {
    return throttle((searchTerm: string) => {
      setClientUsersFilterDefaults((prev) => {
        // merge the previous filtered result with source
        const mergedUsers = [...prev, ...clientUsersOptions];

        // remove duplicates
        const set = new Set();
        const unionArray = mergedUsers.filter((user) => {
          if (!set.has(user.id)) {
            set.add(user.id);
            return true;
          }
          return false;
        }, set);

        const result = unionArray
          .filter((user) => {
            const value = searchTerm.toLowerCase();
            return user.text.toLowerCase().search(value) > -1;
          })
          .sort((a, b) => (a.text > b.text ? 1 : -1));

        return result;
      });
    }, 500);
  }, [clientUsersOptions]);

  const clientFormsFilterSearch = useMemo(() => {
    return throttle((searchTerm: string) => {
      setClientFormFilterDefaults((prev) => {
        // merge the previous filtered result with source
        const mergedClientForms = [...prev, ...clientFormOptions];

        // remove duplicates
        const set = new Set();
        const unionArray = mergedClientForms.filter((user) => {
          if (!set.has(user.id)) {
            set.add(user.id);
            return true;
          }
          return false;
        }, set);

        const result = unionArray
          .filter((user) => {
            const value = searchTerm.toLowerCase();
            return user.text.toLowerCase().search(value) > -1;
          })
          .sort((a, b) => (a.text > b.text ? 1 : -1));

        return result;
      });
    }, 500);
  }, [clientFormOptions]);

  const getUsers = useCallback(() => {
    if (currentClient) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
        const users = res
          .sort((a, b) => (`${a.firstName}` > `${b.firstName}` ? 1 : `${b.lastName}` > `${a.lastName}` ? -1 : 0))
          .map((user) => ({
            id: `${user.id}`,
            text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`,
            value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.id,
          }));
        setClientUsersOptions(users);
        setClientUsersFilterDefaults([...users]);
      });
    }
  }, [currentClient, currentUser?.id, hasPermission]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getClientForms = useCallback(() => {
    if (currentClient) {
      RiskService.getRiskClientForms(currentClient.id, { pageNumber: 1, pageSize: 9999 }).then((res) => {
        const clientForms = res.data.map((x) => ({
          id: x.id,
          text:
            x.documentNumber !== null
              ? `${StringUtils.makePrefixWithNumber(undefined, x.documentNumber, x.templateModuleTranslations)}-${x.subtitle}`
              : x.subtitle,
          value: false,
        }));
        setClientFormOptions(clientForms);
        setClientFormFilterDefaults([...clientForms]);
      });
    }
  }, [currentClient]);

  useEffect(() => {
    getClientForms();
  }, [getClientForms]);

  const clearAllFilters = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  const onSortBy = (expression: string) => {
    setRisks(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const riskFilter = useMemo(() => {
    if (!currentUser) return;
    if (clientUsersOptions.length === 0) return;
    return {
      searchTerm: debouncedSearchTerm,
      clientFormIds: clientFormsFilter.filter((filter) => filter.value).map((filter) => filter.id),
      ownerIds: clientUsersFilters.filter((filter) => filter.value).map((filter) => filter.id),
      statuses: statusFilters.filter((filter) => filter.value).map((filter) => filter.id as RiskStatus),
      ratings: ratingFilters.filter((filter) => filter.value).map((filter) => filter.id as RiskRating),
      pageSize: 15,
      sortBy: sortBy,
    };
  }, [currentUser, clientUsersOptions.length, debouncedSearchTerm, clientFormsFilter, clientUsersFilters, statusFilters, ratingFilters, sortBy]);

  useEffect(
    function resetFilters() {
      setCurrentPage(1);
      setRisks(null);
      // Reset when any of these filters change
    },
    [riskFilter?.searchTerm, riskFilter?.statuses, riskFilter?.ratings, riskFilter?.ownerIds, riskFilter?.sortBy],
  );

  const requestAbortController = useRef<AbortController>();

  const filterRisk = useCallback(() => {
    if (!riskFilter || !currentClient) return;

    const scrollTop = pageContentRef.current?.scrollTop || 0;
    setIsRisksLoading(true);
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    requestAbortController.current = new AbortController();
    RiskService.getRisks(currentClient.id, { ...riskFilter, pageNumber: currentPage }, { signal: requestAbortController.current.signal }).then(
      (res) => {
        setRisks((prev) => {
          if (prev?.data && currentPage > 1) {
            return { ...res, data: [...prev.data, ...res.data] } as ApiResponse<Risk[]> | null;
          }
          return { ...res } as ApiResponse<Risk[]> | null;
        });
        setIsRisksLoading(false);

        nextTick(() => {
          pageContentRef.current?.scrollTo({ top: scrollTop });
        });
      },
    );
  }, [currentClient, currentPage, riskFilter]);

  useEffect(() => {
    filterRisk();
  }, [filterRisk]);

  useEffect(function typeChange() {
    setCurrentPage(1);
    setRisks(null);
  }, []);

  useEffect(function langaugeChanged() {
    const handler = () => setRisks(null);

    EventSystem.listen('language-changed', handler);
    return () => {
      EventSystem.stopListening('language-changed', handler);
    };
  }, []);

  useEffect(() => {
    const handle = (event: QuestionRiskCreatedEvent) => {
      setRisks((prev) => {
        if (!prev) return prev;
        const existingRiskIndex = prev.data.findIndex((risk) => risk.id === event.risk.id);

        if (existingRiskIndex !== -1) {
          return { ...prev, data: prev.data.map((risk, index) => (index === existingRiskIndex ? event.risk : risk)) };
        } else {
          return prev;
        }
      });
    };
    EventSystem.listen('question-risk-created', handle);
    return () => EventSystem.stopListening('question-risk-created', handle);
  }, []);

  return (
    <div className="bg-background-1 flex min-h-full flex-col">
      <div className="h-0 flex-grow overflow-y-auto">
        <TopNavPortal>
          <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
            {t('risk:overview.heading')}
          </Heading>
        </TopNavPortal>
        <div className="px-6 pb-6">
          <div className="-mx-6 mt-0 flex-grow">
            <div className="flex min-h-full flex-col">
              <div className="bg-background-1 sticky top-0 z-40 w-full">
                <div className="flex items-center justify-between px-4 py-1">
                  <div className="flex flex-wrap items-center gap-2">
                    <FilterTag
                      tag={{
                        id: 'clientform',
                        text: t(`common:list.filter.related-to`),
                        value: 'clientform',
                      }}
                      onFiltersChange={applyClientFormsFilter}
                      mode={FilterSelectMode.Multi}
                      options={clientFormsFilter}
                      onSearch={clientFormsFilterSearch}
                    />
                    <FilterTag
                      tag={{
                        id: 'status',
                        text: t(`common:list.filter.status`),
                        value: 'status',
                      }}
                      mode={FilterSelectMode.Multi}
                      onFiltersChange={applyStatusFilter}
                      options={statusFilters}
                    />
                    <FilterTag
                      tag={{
                        id: 'rating',
                        text: t(`common:list.filter.rating`),
                        value: 'rating',
                      }}
                      mode={FilterSelectMode.Multi}
                      onFiltersChange={applyRatingFilter}
                      options={ratingFilters}
                    />

                    {hasPermission(Roles.TeamMember) && (
                      <FilterTag
                        tag={{
                          id: 'user',
                          text: t(`common:list.filter.user`),
                          value: 'user',
                        }}
                        onFiltersChange={applyUsersFilter}
                        mode={FilterSelectMode.Multi}
                        options={clientUsersFilters}
                        onSearch={ownersFilterSearch}
                      />
                    )}
                    {hasFiltersApplied && (
                      <>
                        <div className="border-gray-2 ml-4 mt-4 h-[25px] border-l" />
                        <Button type={ButtonType.TERTIARY} className="mt-4" onClick={clearAllFilters}>
                          <span className="border-primary-1 border-b-2">{t('common:list.filter.clear-all-filters')}</span>
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="flex items-center justify-center gap-4">
                    <div className="flex w-96 gap-2">
                      <SearchInput
                        data-cy="activity-search"
                        placeholder={t('common:list.filter.search')}
                        value={riskSearchPhrase}
                        style={InputStyle.MINIMAL}
                        onChange={(e) => {
                          setCurrentPage(1);
                          setRiskSearchPhrase(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-full w-full flex-grow flex-col" ref={pageContentRef}>
                <div className="h-full px-4 pb-4">
                  <div className="bg-background-1 h-full py-4">
                    <SkeletonLoader type="listBlockRow" rows={5} size="medium" ready={!!risks}>
                      {risks && <RiskList riskPaged={risks} users={clientUsers} sortBy={sortBy} onSort={onSortBy} isLoading={isRisksLoading} />}
                    </SkeletonLoader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RiskModal />
    </div>
  );
};

export default RiskOverview;
