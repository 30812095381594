import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiResponse } from '../../models/ApiResponse';
import SortableHeading from '../shared/data-grid/SortableHeading';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import Loader from '../shared/Loader';
import DocumentSpaceIcon from '../shared/icon/DocumentSpaceIcon';
import { Risk, RiskCategory } from '../../models/Risk';
import RiskRow from './RiskRow';
import RiskService from '../../services/RiskService';
import User from '../../models/User';

type DocumentListProps = {
  riskPaged: ApiResponse<Risk[]>;
  users: User[];
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading?: boolean;
};

const RiskList: FC<DocumentListProps> = (props) => {
  const { riskPaged, onLoadMore, onSort, sortBy, isLoading = false, users } = props;
  const [riskCategory, setRiskCategory] = useState<RiskCategory>();

  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});

  const { t } = useTranslation(['risk', 'common']);
  const currentPage = riskPaged.pageNumber || 0;
  const totalPages = riskPaged.totalPages || 0;

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  useEffect(() => {
    RiskService.getRiskCategories({ pageNumber: 1, pageSize: 1 }).then((res) => {
      setRiskCategory(res.data[0]);
    });
  }, []);

  const validRisk = useMemo(() => riskPaged.data.filter((x) => x.isValid), [riskPaged.data]);

  return (
    <>
      <div className="bg-gray-5 rounded-[10px] px-2 py-3">
        <div className="flex w-full items-end border-2 border-transparent pl-4">
          <div className="w-10">
            <SortableHeading title={t('risk:list.id')} onSort={updateSortExpression} expression={sortExpressions['friendlyId']} />
          </div>
          <div className={`flex flex-grow items-end text-left`}>
            <SortableHeading title={t('risk:list.title')} onSort={updateSortExpression} expression={sortExpressions['title']} />
          </div>
          <div className="w-40 xl:w-60">
            <SortableHeading title={t('risk:list.related')} onSort={updateSortExpression} expression={sortExpressions['subtitle']} />
          </div>
          <div className="w-40 xl:w-52">
            <SortableHeading title={t('risk:list.status')} onSort={updateSortExpression} expression={sortExpressions['status']} />
          </div>
          <div className="w-52">
            <SortableHeading title={t('risk:list.probability')} onSort={updateSortExpression} expression={sortExpressions['matrixHorizontalLevel']} />
          </div>
          <div className="w-40">
            <SortableHeading title={t('risk:list.impact')} onSort={updateSortExpression} expression={sortExpressions['matrixVerticalLevel']} />
          </div>
          <div className="w-32">
            <SortableHeading title={t('risk:list.rating')} onSort={updateSortExpression} expression={sortExpressions['riskRating']} />
          </div>
          <div className="w-44">
            <SortableHeading title={t('risk:list.modified')} onSort={updateSortExpression} expression={sortExpressions['lastModifiedUtc']} />
          </div>
          <div className="w-40">
            <SortableHeading title={t('risk:list.owner')} onSort={updateSortExpression} expression={sortExpressions['ownerName']} />
          </div>
          <div className="w-20">{/* SPACER */}</div>
        </div>

        {riskCategory &&
          users.length > 0 &&
          validRisk.map((riskItem, i) => {
            const isLast = validRisk.length === i + 1;
            return (
              <div key={riskItem.id} ref={isLast ? lastElementRef : undefined}>
                <RiskRow risk={riskItem} riskCategory={riskCategory} owner={users.find((x) => x.id === riskItem.ownerId)} />
              </div>
            );
          })}
      </div>

      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}

      {!isLoading && validRisk.length === 0 && (
        <div data-cy="risk-empty" className="mx-auto mt-24 flex w-1/2 flex-col items-center justify-center p-16 text-center">
          <DocumentSpaceIcon className="bg-primary-1 text-primary-1 h-16 w-16 rounded-full bg-opacity-10 p-4" />
          <div className="text-dpm-20 text-color-3 mt-8">{t(`risk:list.no-risk-filtered.heading`)}</div>
          <p className="text-color-3 pb-4">{t(`risk:list.no-risk-filtered.description`)}</p>
        </div>
      )}
    </>
  );
};

export default RiskList;
